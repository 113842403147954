import React, { useMemo } from "react";
import useSolutionsData from "@staticQueries/SolutionsQuery";
import useModulesData from "@staticQueries/ModulesQuery";
import { SolutionCard } from "@molecules";
import { Container } from "@atoms";

const SolutionSelect = ({ byo }) => {
  const _solutions = useSolutionsData();
  const _modules = useModulesData();

  const modules = useMemo(
    () =>
      _modules
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(mod => ({ ...mod })),
    []
  );

  const solutions = _solutions
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((s, i) => ({
      ...s,
      modules: modules.filter(mod => s.moduleList.includes(mod.uid)),
    }));

  return (
    <Container>
      {/* choose solution bundle */}
      <ul className="flex flex-wrap justify-center gap-5">
        <li className="flex flex-grow sm:flex-[0_1_calc(50%-.625rem)] lg:flex-[0_1_calc(33.333%-.875rem)]">
          <SolutionCard customIcon="byos" url="/solution-builder/" {...byo} />
        </li>
        {solutions.map(s => {
          return (
            <li
              className="flex flex-grow sm:flex-[0_1_calc(50%-.625rem)] lg:flex-[0_1_calc(33.333%-.875rem)]"
              key={s.uid}
            >
              <SolutionCard
                {...s}
                cta="Choose this bundle"
                url={`/solution-builder/#${s.slug}`}
              />
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default SolutionSelect;
